/* src/components/Home.css */

.home-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .home-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .home-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .home-cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .home-card {
    flex: 1;
    min-width: 200px;
    max-width: 250px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .home-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .home-card h2 {
    font-size: 20px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .home-card p {
    font-size: 14px;
    color: #666;
  }
  