/* src/App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.navbar {
  background-color: #007bff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-link:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.active-link {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    margin-bottom: 10px;
  }
}
